import React from "react";
import {
  Typography,
  Grid,
  Divider,
  Button,
} from "@material-ui/core";
import DateView from "../../framework/components/DateView";
import { Player } from "../../store/players/models";
import { Data } from "../../framework/data";
import { Club } from "../../store/clubs/models";
import OpenLeagueClubs from "../leagues/OpenLeagueClubs";
import { TournamentDetails } from "../../store/tournaments/models";
import LeagueRanking from "../leagues/LeagueRanking";
import PricesList from "../leagues/PricesList";
import { LeagueStatus, LeaguePlayer } from "../../store/leagues/models";
import { Link } from "react-router-dom";

interface TournamentDetailsProps {
  tournament: TournamentDetails;
  player?: Player;
  clubs: Data<Club[]>;
  onRegisterMatchResultWithPlayerRequested: (x: LeaguePlayer) => void;
}

const calcFinalsIframeHeight = (players: any[]) => {
  if (players.length <= 16) return 654;
  return 1252;
}

const TournamentDetailsComponent: React.FC<TournamentDetailsProps> = ({
  tournament,
  player,
  clubs,
  onRegisterMatchResultWithPlayerRequested
}) => {
  return (
    <>
      <Typography variant="h4" color="secondary">
        {tournament.city} - {tournament.name}
      </Typography>

      <div style={{ padding: "32px 0" }}>

        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h5" color="primary">
              Lista rankingowa
                </Typography>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              color="secondary"
              to={`/tournaments/${tournament.year}/${tournament.id}/matches`}
            >
              Wszystkie mecze
                </Button>
            {!!tournament.isCurrentUserAssigned ? (
              <Button
                component={Link}
                color="secondary"
                to={`/tournaments/${tournament.year}/${tournament.id}/mine-matches`}
              >
                Moje mecze
                  </Button>
            ) : (
                <></>
              )}
          </Grid>
        </Grid>

        <LeagueRanking
          clubs={clubs}
          mode={(!!tournament.ladderUrl) ? "player-list" : "full"}
          leagueId={tournament.id}
          leagueYear={tournament.year}
          players={tournament.players.map(x => ({ ...x, canIPlay: true }))}
          leagueStatus={LeagueStatus.InProgress}
          onRegisterMatchResultWithPlayer={onRegisterMatchResultWithPlayerRequested}
          currentPlayer={player}></LeagueRanking>
        <Divider />

        {
          !!tournament.ladderUrl ? (
            <iframe src={tournament.ladderUrl || "about:blank"} style={{ width: "100%", height: calcFinalsIframeHeight(tournament.players || []), border: 0, margin: '1px', padding: '1px' }} frameBorder={0}>
            </iframe>
          ) : (<></>)
        }
      </div>
      <div style={{ padding: "32px 0" }}>
        <OpenLeagueClubs clubs={tournament.clubs}></OpenLeagueClubs>
      </div>
      <div style={{ padding: "32px 0" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" color="primary">
              Dane turnieju
            </Typography>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="body2">
                  <b>Czas trwania:</b>{" "}
                  <DateView value={tournament.startDate}></DateView> -{" "}
                  <DateView value={tournament.endDate}></DateView>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Uczestników:</b> {tournament.players.length} / {tournament.maxPlayers}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Nagrody</b>
                </Typography>
                <PricesList prices={tournament.prices}></PricesList>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Opis</b>
                </Typography>
                <Typography variant="body2">{tournament.description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>);
};

export default TournamentDetailsComponent;
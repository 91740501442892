import React from "react";
import {
  LeagueAccess,
  LeaguePlayer,
  LeagueView,
  LeagueStatus,
  LeagueWithPlayersICanPlay
} from "../../store/leagues/models";
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Chip,
  Divider,
} from "@material-ui/core";
import OpenLeagueClubs from "./OpenLeagueClubs";
import PrivateLeagueClub from "./PrivateLeagueClub";
import DateView from "../../framework/components/DateView";
import PricesList from "./PricesList";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LeagueRanking from "./LeagueRanking";
import { Player } from "../../store/players/models";
import { Data } from "../../framework/data";
import { MatchHeader } from "../../store/matches/models";
import NewestMatches from "../matches/NewestMatches";
import { Link } from "react-router-dom";

import { Club } from "../../store/clubs/models";
import SportsTennisIcon from "@material-ui/icons/SportsTennis";
import { PlayerType } from "../../store/registration/model";
import { Alert } from "@material-ui/lab";

interface LeagueDetailsProps {
  league: LeagueWithPlayersICanPlay;
  player?: Player;
  newestMatches: Data<MatchHeader[]>;
  clubs: Data<Club[]>;
  onSubmitToLeagueRequested: (x: LeagueWithPlayersICanPlay) => void;
  onRegisterMatchResultWithPlayerRequested: (x: LeaguePlayer) => void;
}

const calcFinalsIframeHeight = (players: any[]) => {
  if (players.length <= 16) return 654;
  return 1252;
}

const LeagueDetails: React.FC<LeagueDetailsProps> = ({
  league,
  onSubmitToLeagueRequested,
  player,
  onRegisterMatchResultWithPlayerRequested,
  newestMatches,
  clubs,
}) => {
  return (
    <>
      <Typography variant="h4" color="secondary">
        {league.name}
      </Typography>

      <div style={{ padding: "32px 0" }}>
        {league.currentView === LeagueView.RankingList ? (
          <>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h5" color="primary">
                  Lista rankingowa
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  color="secondary"
                  to={`/leagues/${league.year}/${league.id}/matches`}
                >
                  Wszystkie mecze
                </Button>
                {!!league.isCurrentUserAssigned ? (
                  <Button
                    component={Link}
                    color="secondary"
                    to={`/leagues/${league.year}/${league.id}/mine-matches`}
                  >
                    Moje mecze
                  </Button>
                ) : (
                    <></>
                  )}
                {
                  (!!league.isCurrentUserAssigned && !!player && league.players.find(x => x.id === player.id)?.playerType === PlayerType.TRIAL)
                    ? (<Button component={Link} color="primary" to={`/signup?leagueId=${league.id}&leagueYear=${league.year}`}>
                      Przejdź na konto SMART
                  </Button>)
                    : (<></>)
                }
              </Grid>
            </Grid>

            {
              (league.startDate < new Date()) ? (
                <LeagueRanking
                  clubs={clubs}
                  leagueStatus={league.status}
                  mode={(!!league.finalsUrl) ? "player-list" : "full"}
                  leagueId={league.id}
                  leagueYear={league.year}
                  players={league.players}
                  currentPlayer={player}
                  onRegisterMatchResultWithPlayer={
                    onRegisterMatchResultWithPlayerRequested
                  }
                ></LeagueRanking>
              ) : (
                  <Alert severity="info">
                    Lista zawodników udostępniona zostanie po starcie ligi
                </Alert>
                )
            }



            <Divider />

            {
              !!league.finalsUrl ? (
                <iframe src={league.finalsUrl || "about:blank"} style={{ width: "100%", height: calcFinalsIframeHeight(league.players || []), border: 0, margin: '1px', padding: '1px' }} frameBorder={0}>
                </iframe>
              ) : (<></>)
            }
          </>
        ) : (
            <>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h5" color="primary">
                    Plan rozgrywek turniejowych
                </Typography>
                </Grid>
                <Grid item>
                  {league.admins &&
                    !!player &&
                    !!league.admins.find((x) => x === player.id) &&
                    !league.finals ? (
                      <Button
                        component={Link}
                        color="secondary"
                        to={`/leagues/${league.year}/${league.id}/plan`}
                      >
                        Zaplanuj
                  </Button>
                    ) : (
                      <></>
                    )}
                </Grid>
              </Grid>
            </>
          )}
      </div>
      <div style={{ padding: "32px 0" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" color="primary">
              Najnowsze wyniki
            </Typography>
            {newestMatches.type === "LOADED" ? (
              <NewestMatches matches={newestMatches.value}></NewestMatches>
            ) : (
                <div
                  style={{
                    width: "64px",
                    height: "64px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" color="primary">
              Dane ligi
            </Typography>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="body2">
                  <b>Czas trwania:</b>{" "}
                  <DateView value={league.startDate}></DateView> -{" "}
                  <DateView value={league.endDate}></DateView>
                </Typography>
              </Grid>
              {
                league.startDate < new Date() ? (
                  <Grid item>
                    <Typography variant="body2">
                      <b>Uczestników:</b> {league.runtimeInfo.participantsCount}
                    </Typography>
                  </Grid>
                ) : (<></>)
              }
              <Grid item>
                <Typography variant="body2">
                  <b>Rozegranych meczów:</b> {league.runtimeInfo.matchesPlayed}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Nagrody</b>
                </Typography>
                <PricesList prices={league.prices}></PricesList>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Opis</b>
                </Typography>
                <Typography variant="body2">{league.description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "32px 0" }}>
        {league.type.access === LeagueAccess.Public ||
          league.type.access === LeagueAccess.Tournament ? (
            <OpenLeagueClubs clubs={league.type.supportedClubs}></OpenLeagueClubs>
          ) : (
            <PrivateLeagueClub club={league.type.owner}></PrivateLeagueClub>
          )}
      </div>
    </>
  );
};

export default LeagueDetails;
import React from "react";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import DateView from "../../framework/components/DateView";
import { Player } from "../../store/players/models";
import { Data } from "../../framework/data";
import { Club } from "../../store/clubs/models";
import { TrainingDetails } from "../../store/trainings/models";
import LeagueRanking from "../leagues/LeagueRanking";
import { LeagueStatus } from "../../store/leagues/models";

interface TrainingDetailsProps {
  training: TrainingDetails;
  player?: Player;
  clubs: Data<Club[]>;
}

const TrainingDetailsComponent: React.FC<TrainingDetailsProps> = ({
  training,
  player,
  clubs
}) => {
  return (
    <>
      <Typography variant="h4" color="secondary">
        {training.city} - {training.name}
      </Typography>

      <div style={{ padding: "32px 0" }}>

        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h5" color="primary">
              Lista uczestników
                </Typography>
          </Grid>
        </Grid>

        <LeagueRanking
          clubs={clubs}
          mode={"player-list"}
          leagueId={training.id}
          leagueYear={training.year}
          players={training.subscribedPlayers.map(x => ({ ...x, canIPlay: true }))}
          leagueStatus={LeagueStatus.InProgress}
          onRegisterMatchResultWithPlayer={() => { }}
          currentPlayer={player}></LeagueRanking>

      </div>
      <div style={{ padding: "32px 0" }}>

        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h5" color="primary">
              Lista rezerwowa
                </Typography>
          </Grid>
        </Grid>

        <LeagueRanking
          clubs={clubs}
          mode={"player-list"}
          leagueId={training.id}
          leagueYear={training.year}
          players={training.waitingQueuePlayers.map(x => ({ ...x, canIPlay: true }))}
          leagueStatus={LeagueStatus.InProgress}
          onRegisterMatchResultWithPlayer={() => { }}
          currentPlayer={player}></LeagueRanking>
      </div>
      <div style={{ padding: "32px 0" }}>
        <Typography variant="h5" color="primary">
          Dane treningu
            </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">
              <b>Opis</b>
            </Typography>
            <Typography variant="body2">{training.description}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>

            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="body2">
                  <b>Czas trwania:</b>{" "}
                  <DateView value={training.startDate}></DateView> -{" "}
                  <DateView value={training.endDate}></DateView>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Uczestników:</b> {training.subscribedPlayers.length} / {training.maxPlayers}
                </Typography>
              </Grid>
              <Grid item>
              <Typography variant="body2">
                  <b>Cena:</b> {training.basePrice} PLN
                </Typography>
              </Grid>
              <Grid item>

              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
    </>);
};

export default TrainingDetailsComponent;